@use 'src/styles/typography';

:root[data-theme='light'] {
  --tooltip-search-bg-color: var(--white-color);
  --tooltip-search-placeholder-color: rgba(6, 21, 43, 0.5);
  --icon-color: #06152b;
  --filters-divider-color: rgba(0, 0, 0, 0.1);
}

:root[data-theme='dark'] {
  --tooltip-search-bg-color: var(--primary-3);
  --tooltip-search-placeholder-color: var(--secondary-1);
  --icon-color: #fff;
  --filters-divider-color: rgba(255, 255, 255, 0.1);
}

.topButtonsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .iconButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 28px;
    cursor: pointer;
    color: var(--contrast);

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}

.tooltipButton {
  width: 36px;
  padding: 4px 16px;
  flex-shrink: 0;
  background: none;
  border: none;
  color: var(--icon-color);

  &:last-of-type {
    margin-bottom: 0;
  }

  &Active,
  &Active:hover {
    background-color: var(--brand-1);
    color: #fff;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.search {
  @extend%body-m;
  width: 265px;
  height: 32px;
  background: var(--tooltip-search-bg-color);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::placeholder {
    color: var(--tooltip-search-placeholder-color);
  }
  &::-webkit-input-placeholder {
    color: var(--tooltip-search-placeholder-color);
  }
  &:-moz-placeholder {
    color: var(--tooltip-search-placeholder-color);
  }
}

.filtersValues {
  max-height: 400px;
  margin-bottom: 0;
  overflow-y: auto;
}

.checkItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &Text {
    @extend%body-m;
    margin-left: 16px;
    color: var(--contrast);
  }
}

.bottomButtonsContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;

  .button {
    width: 100%;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.applyButton {
  color: var(--white-color);
}

.divider {
  margin: 8px 0;
  border-color: var(--filters-divider-color);
}
